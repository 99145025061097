import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';
import BasicModal from '../../../components/BasicModal';

import { fetchDeviceInfo } from '../../equipment/actions';

import { getAlarm, getAlarmDevice } from '../selectors';
import { relativeDateFormatter } from '../../../components/table/formatters';
import AlarmPossibleCausesImages from './AlarmPossibleCausesImages';
import { AlarmCauses } from '../../equipment/components/DeviceAlarmCard';
import { Alert, Row } from 'react-bootstrap';

function AlarmPossibleCausesModal({
  device = {},
  alarm = {},
  fetchDeviceInfo,
  children,
  onShow,
}) {

  useEffect(() => {
    if (device && device.id && !device.serial) {
      fetchDeviceInfo({id: device.id});
    }
  }, [device.id]);

  const { t } = useTranslation();
  const titles = alarm.possible_causes.map(({ title }) => title);
  const [index, setIndex] = useState(0);

  const handleChildClick = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const resetIndex = () => {
    setIndex(0);
  };

  return (
    <BasicModal
      size="lg"
      header={(
        <Title title={t(`components.equipment.possible-causes.title.${titles[index]}`)} />
      )}
      subHeader={(
        <div>
          <p>
            {[
              alarm.site_name,
              alarm.site_subarea_name,
              alarm.equipment_name
            ].filter(Boolean).join(' - ')}
          </p>
          <p>
            <Link to={`/equipment/${alarm.device_id}`}>
              {device.serial}
            </Link>
            {' - '}
            {relativeDateFormatter(alarm.alarm_timestamp)}
          </p>
          {
            alarm.possible_causes?.length > 0 &&
            <Row className="small-gutters">
              <AlarmCauses alarm={alarm} thisIndex={index} layout={'horizontal'} />
            </Row>
          }
          <Alert variant="info" className="possible-causes-modal-alert">
            {t(`components.equipment.possible-causes.description.${alarm.possible_causes[index].description}`)}
          </Alert>
        </div>
      )}
      body={(
        <div>
          <AlarmPossibleCausesImages
            alarm={alarm}
            handleChildClick={handleChildClick}
          />
        </div>
      )}
      onShow={onShow}
      onClose={resetIndex}
    >
      {children}
    </BasicModal>
  );
}

const mapStateToProps = (state, {alarmId}) => {
  const alarm = getAlarm(state, alarmId);
  return {
    alarm,
    device: getAlarmDevice(state, alarmId),
  };
};

const mapDispatchToProps = {fetchDeviceInfo};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmPossibleCausesModal);
