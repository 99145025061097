import React from 'react';
import { connect } from 'react-redux';

import { getAlarmDevice } from '../selectors';

import ControlledCarousel from './Carousel';

function AlarmPossibleCausesImages({
  alarm,
  handleChildClick,
}) {

  return (
    <ControlledCarousel
      handleChildClick={handleChildClick}
      images={alarm.possible_causes}
    />
  );
}

const mapStateToProps = (state, { alarmId }) => {
  const device = getAlarmDevice(state, alarmId);
  return {
    deviceId: device?.id,
  };
};

export default connect(mapStateToProps, null)(AlarmPossibleCausesImages);
