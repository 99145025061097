import { Carousel, Image } from 'react-bootstrap';
import { useState } from 'react';

function ControlledCarousel({ images, handleChildClick }) {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    handleChildClick(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={null} indicators={false}>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          {/* image.type === 'html' ? DASH-1000 */}
          <div className="possible-causes-modal-content">
            <Image
              src={image.path}
              height="300"
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ControlledCarousel;
