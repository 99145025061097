
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import Bar from '../../../components/Bar';
import VolumeImage from '../../alarm/VolumeImage';
import ToneImage from '../../alarm/ToneImage';
import TemperatureImage from '../../alarm/TemperatureImage';
import AlarmFFTModal from '../../alarm/components/AlarmFFTModal';
import AlarmPossibleCausesModal from '../../alarm/components/AlarmPossibleCausesModal';
import AlarmSoundButton from '../../alarm/components/AlarmSoundButton';
import { FitMachineActivityCard } from './DeviceCard';

import DeviceAlarmStatus from './DeviceAlarmStatus'; // - New event status workflow.
import DeviceAlarmEvents from './DeviceAlarmEvents';
import ConfirmModal from '../../../components/ConfirmModal';

import { fetchDeviceAlarms } from '../actions';
import { fetchAlarmEvents, deleteAlarm, fetchAlarms } from '../../alarm/actions';
import { getAlarm, getAlarmDevice } from '../../alarm/selectors';
import { getUserId, isAdmin } from '../../user/selectors';
import { getDeviceHasProductCode } from '../selectors';
import { useTrackDispatch } from '../../app/hooks';
import { getGroupTree } from '../../organisation/selectors';
import { selectActiveGroup } from '../../organisation/actions';
import { getSensorName } from '../utils';
import './deviceAlarmCard.scss';
import useDomain from '../../domain/hooks/useDomain';
import { InternalLink } from '../../../lib/utils';
import BasicTooltip from '../../../components/BasicTooltip';

function AlarmImpactButton({ alarmId }) {
  const { t } = useTranslation();
  if(!alarmId) return null;
  return <Link to={`/alarms/${alarmId}/impact`} className="btn btn-primary">
    {t('common.event-impact')}
  </Link>;
}

function PossibleCauses({ index, className, cause }) {
  const { t } = useTranslation();
  const { domainData: themes } = useDomain({ key: 'themes' });
  return (
    <BasicTooltip key={index} text={t(`components.equipment.possible-causes.tooltip.${cause.caption}`)}>
      <div
        key={index}
        className={className}
        style={{
          backgroundColor: themes?.[cause.theme]?.colour, color: themes?.[cause.theme]?.text_colour
        }}
      >
        {t(`components.equipment.possible-causes.caption.${cause.caption}`)}
      </div>
    </BasicTooltip>
  );
}

export function AlarmCauses({ alarm, thisIndex }) {
  const { t } = useTranslation();
  return (
    <>
      <Col xs="auto" className="mb-1">{t('components.equipment.possible-causes.header')}: </Col>
      {alarm.possible_causes[thisIndex].causes.map((cause, index) => {
        return (
          <Col xs="auto" className="mb-1" key={index}>
            <PossibleCauses
              index={index}
              className="badge badge-secondary d-inline mb-1"
              cause={cause}
              key={index}
            />
          </Col>
        );
      })}
    </>
  );
}

export function AlarmCausesSummary({ alarm }) {
  const { t } = useTranslation();
  return (
    <>
      <p className="title mb-0">{t('components.equipment.possible-causes.header')}</p>
      {alarm.possible_causes_summary.map((cause, index) => {
        return (
          <PossibleCauses
            index={index}
            className="badge badge-secondary d-block mb-1"
            cause={cause}
            key={index}
          />
        );
      })}
    </>
  );
}

function FitMachineConditionAlarmCard({ alarm={}, device={} }) {
  const { t } = useTranslation();
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';
  const trackDispatch = useTrackDispatch();
  const sensorName = getSensorName(device);

  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_type === 'red' && (
            // red alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                <Trans
                  i18nKey="components.equipment.device-alarm-card.detected-major"
                  values={{sensorName: sensorName}}
                  components={{
                    alarmLink: <InternalLink
                      to={`/equipment/${alarm.device_id}`}
                      text={device.equipment_name}
                    />
                  }}
                />
              </p>
            </Col>
          )}
          {alarm.alarm_type === 'yellow' && (
            // yellow alarm
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              <p className="mb-1">
                <Trans
                  i18nKey="components.equipment.device-alarm-card.detected-minor"
                  values={{sensorName: sensorName}}
                  components={{
                    alarmLink: <InternalLink
                      to={`/equipment/${alarm.device_id}`}
                      text={device.equipment_name}
                    />
                  }}
                />
              </p>
            </Col>
          )}
          <Col xs="auto" className="flow-col-lg flex-grow-1">
            <Row>
              <Col xs="auto" className="flow-col-xs flex-grow-1 mb-3">
                <p className="title mb-0">{t('components.equipment.device-alarm-card.condition')}</p>
                <div className="mt-2 mb-1">
                  <Bar conditionValue={alarm.condition_overall} />
                </div>
              </Col>
              <Col xs="auto" className="flow-col-sm flex-grow-1">
                <Row className="small-gutters">
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">{t('components.equipment.device-alarm-card.ai-vol')}</p>
                    <div className="mb-1">
                      <VolumeImage rms_value={alarm.rms} isoClass={alarm.equipment_iso_class} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">{t('components.equipment.device-alarm-card.ai-tone')}</p>
                    <div className="mb-1">
                      <ToneImage vibration_condition={alarm.condition_vibration} />
                    </div>
                  </Col>
                  <Col xs="auto" className="flow-col-xxs mb-3">
                    <p className="title mb-0">{t('components.equipment.device-alarm-card.ai-temp')}</p>
                    <div className="mb-1">
                      <TemperatureImage temperature_condition={alarm.condition_temperature} />
                    </div>
                  </Col>
                </Row>
              </Col>
              {
                alarm.possible_causes_summary?.length > 0 &&
                <Col xs="auto" className="mb-3">
                  <AlarmCausesSummary alarm={alarm} />
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row className="small-gutters">
          <Col xs="auto" className="mb-1" >
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>{t('components.equipment.device-alarm-card.view-fft')}</Tooltip>
              )}
            >
              {triggerProps => (
                // set overlay trigger on button not entire modal
                <AlarmFFTModal alarmId={alarm.id} onShow={() => { trackDispatch({location: 'Activity', alarm_id: alarm.id, action: 'fftsmudge'}); }} >
                  <Button
                    {...triggerProps}
                    variant="outline-secondary"
                    className="mb-0"
                  >
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-area-chart"
                      title="FFT"
                    /> <span>FFT</span>
                  </Button>
                </AlarmFFTModal>
              )}
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>{t('components.equipment.device-alarm-card.audio-recreation-alarm')}</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="alarm"
                className="mb-0"
                actionType="alarmaudio"
              >
                <span>{t('Alarm')}</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
          <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>{t('components.equipment.device-alarm-card.audio-recreation-learning')}</Tooltip>
              )}
            >
              <AlarmSoundButton
                variant="outline-secondary"
                alarmId={alarm.id}
                soundType="baseline"
                className="mb-0"
                actionType="learningaudio"
              >
                <span>{t('components.equipment.device-alarm-card.learning-period')}</span>
              </AlarmSoundButton>
            </OverlayTrigger>
          </Col>
          {alarm.possible_causes?.length > 0 && <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>{t('components.equipment.possible-causes.view-possible-causes')}</Tooltip>
              )}
            >
              {triggerProps => (
                // set overlay trigger on button not entire modal
                <AlarmPossibleCausesModal alarmId={alarm.id} onShow={() => {
                  trackDispatch({location: 'Activity', alarm_id: alarm.id, action: 'fftsmudge'});
                }}>
                  <Button
                    {...triggerProps}
                    variant="outline-secondary"
                    className="mb-0"
                  >
                    <Icon
                      iconSize="1.2em"
                      icon="series-search"
                      title="Diagnosis"
                    /> <span>{t('components.equipment.possible-causes.diagnosis')}</span>
                  </Button>
                </AlarmPossibleCausesModal>
              )}
            </OverlayTrigger>
          </Col>}
        </Row>
      </Card.Text>
    </FitMachineActivityCard>
  );
}

function FitMachineThresholdAlarmCard({ alarm={}, device={} }) {
  const { t } = useTranslation();
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';
  const trackDispatch = useTrackDispatch();
  const sensorName = getSensorName(device);
  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-3">
              {alarm.alarm_type === 'normal' ?  // If alarm type is normal, show 'normal' messages.
                <p className="mb-1">
                  <Trans
                    i18nKey="components.equipment.device-alarm-card.detected-normal"
                    values={{
                      sensorName: sensorName,
                      alarmDescription: alarm.alarm_description,
                    }}
                    components={{
                      alarmLink: <InternalLink
                        to={`/equipment/${alarm.device_id}`}
                        text={device.equipment_name}
                      />
                    }}
                  />
                </p> :  // Otherwise, show messages for alarm. The flip side of 'normal' is just 'alarm'.
                <p className="mb-1">
                  <Trans
                    i18nKey="components.equipment.device-alarm-card.detected-threshold"
                    values={{
                      sensorName: sensorName,
                      alarmDescription: alarm.alarm_description,
                    }}
                    components={{
                      alarmLink: <InternalLink
                        to={`/equipment/${alarm.device_id}`}
                        text={device.equipment_name}
                      />
                    }}
                  />
                </p>
              }
              {alarm.trigger_description && (
                <p className="mb-1">
                  Trigger: {alarm.trigger_description}
                </p>
              )}
            </Col>
          )}
          <Col xs="auto">
            <Row>
              {
                alarm.possible_causes_summary?.length > 0 &&
                <Col xs="auto" className="mb-3 margin-right-0">
                  <AlarmCausesSummary alarm={alarm} />
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <Row>
          {alarm.possible_causes?.length > 0 && <Col xs="auto" className="mb-1">
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>{t('components.equipment.possible-causes.view-possible-causes')}</Tooltip>
              )}
            >
              {triggerProps => (
                // set overlay trigger on button not entire modal
                <AlarmPossibleCausesModal alarmId={alarm.id} onShow={() => {
                  trackDispatch({location: 'Activity', alarm_id: alarm.id, action: 'fftsmudge'});
                }}>
                  <Button
                    {...triggerProps}
                    variant="outline-secondary"
                    className="mb-0"
                  >
                    <Icon
                      iconSize="1.2em"
                      icon="series-search"
                      title="Diagnosis"
                    /> <span>Diagnosis</span>
                  </Button>
                </AlarmPossibleCausesModal>
              )}
            </OverlayTrigger>
          </Col>}
        </Row>

      </Card.Text>
    </FitMachineActivityCard>
  );
}

function FitMachineUserAlarmCard({ alarm={}, deleteAlarm, userId, isAdmin, userCanDeleteAlarm }) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const isAlarmImpactAvailable = (alarm.was_current || alarm.is_current || alarm.is_legacy) && alarm.alarm_status !== 'new';

  const handleDeleteAlarm = useCallback(async () => {
    const isAtAlarmPage = /\/alarms\/\d+/.test(location.pathname);
    setDeleting(true);
    try {
      await deleteAlarm(alarm);
      if(isAtAlarmPage) {
        history.push('/alarms/list/user');
      }
    } catch(e) {
    } finally {
      setDeleting(false);
    }
  }, [alarm, deleteAlarm]);

  return (
    <FitMachineActivityCard
      alarm={alarm}
      deviceId={alarm.device_id}
      className={`fitmachine-alarm-card no-footer-padding`}
      beforeFooter={<DeviceAlarmStatus alarmId={alarm.id} />}
      footer={<DeviceAlarmEvents alarmId={alarm.id} />}
      sideButton={isAlarmImpactAvailable && <AlarmImpactButton alarmId={alarm.id} />}
    >
      <Card.Text as="div">
        <Row>
          {alarm.alarm_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-2">
              <div className="font-weight-bold">
                {alarm.alarm_description}
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {alarm.trigger_description && (
            <Col xs="auto" className="flow-col-md flex-grow-1 mb-2">
              <div>
                {alarm.trigger_description}
              </div>
            </Col>
          )}
        </Row>
        {(userCanDeleteAlarm &&
          alarm.alarm_status === 'new' &&
          (userId === alarm.created_by_id || isAdmin )) &&
          <div className="d-flex justify-content-end">
            <ConfirmModal
              header={t('components.equipment.device-alarm-card.header')}
              confirmText={t('common.delete')}
            >
              <Button
                variant="danger"
                onClick={handleDeleteAlarm}
                className="mr-1"
                disabled={deleting}
              >{t('common.delete')}</Button>
            </ConfirmModal>
          </div>
        }
      </Card.Text>
    </FitMachineActivityCard>
  );
}

// render the correct alarm card type
function FitMachineAlarmCard({
  alarmId,
  alarm,
  device={},
  fetchAlarmEvents,
  deleteAlarm,
  userId,
  isAdmin,
  userCanDeleteAlarm,
  groupTree,
  selectActiveGroup,
}) {

  // fetch alarm events, they will be needed further down in the cards
  useEffect(() => {
    if (alarmId) {
      fetchAlarmEvents({ id: alarmId });
    }
  }, [alarmId]);

  if(!alarm &&
    groupTree &&
    groupTree.id) {
    selectActiveGroup(groupTree.id);
  }

  return alarm && alarm.alarm_source ? (
    alarm.alarm_source === 'threshold' ? (
      <FitMachineThresholdAlarmCard alarm={alarm} device={device} />
    ) :
      alarm.alarm_source === 'user' ? (
        <FitMachineUserAlarmCard
          alarm={alarm}
          device={device}
          deleteAlarm={deleteAlarm}
          userId={userId}
          isAdmin={isAdmin}
          userCanDeleteAlarm={userCanDeleteAlarm}
        />
      ) :
        (
          <FitMachineConditionAlarmCard alarm={alarm} device={device} />
        )
  ) : null;
}

const mapStateToProps = (state, { alarmId }) => {
  return {
    alarmId,
    device: getAlarmDevice(state, alarmId),
    alarm: getAlarm(state, alarmId),
    userId: getUserId(state),
    isAdmin: isAdmin(state),
    userCanDeleteAlarm: getDeviceHasProductCode(state, getAlarmDevice(state, alarmId)?.id, 'user_alarms'),
    groupTree: getGroupTree(state),
  };
};
const mapDispatchToProps = {
  fetchAlarms,
  fetchDeviceAlarms,
  fetchAlarmEvents,
  deleteAlarm,
  selectActiveGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(FitMachineAlarmCard);
